:root {
    --animation-duration: 0.5s;
  }
  
  html, body {
    width: 100%;
    overflow-x: hidden;
    
    margin: 0;
    padding: 0;
  }
  
  .animate-in {
    animation: animate-in var(--animation-duration) ease-in-out linear;
  }
  
  @keyframes animate-in {
    0% {
      opacity: 0;
      transform: translateY(1rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0rem);
    }
  }
  
  /* Customize scrollbar if needed */
  * {
    scrollbar-width: auto;
    scrollbar-color: #c7c7c7 #ededed;
  }
  
  *::-webkit-scrollbar {
    width: 15px;
  }
  
  *::-webkit-scrollbar-track {
    background: #ededed;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 5px;
    border: 2px solid #ffffff;
  }
  


  .Light_Gray{
    background-color: #E9E9E9;
  }
  .Light_Gray_1{
    background-color: #E9E9E9;

  }
  .Dark_Purple_Gradient{
    background-image: linear-gradient(288.21deg, #412653 4.1%, #3F576F 99.58%);
  }
  .Charcoals{
    background-color: #1D181F;
  }
  .Charcoal_1{
    background-color: #1D181F;
    
    
  }
  .dark_shade_gary{
    background-color:#2C2B30;
    
  }
  .Cream{
    background-color: #FFFBEF;
  }
  .Soft_Gray{
    background-color: #EFEFEF;
  }
  .Muted_Beige{
    background-color: #E2D8D0;
  }
  .black_1{
    background-color: #121212;

  }
  .control{
    background-color: #F2F2F3;

    
  
  }